/* Rajdhani */
@font-face {
  font-family: "Rajdhani";
  src: url("./Rajdhani/Rajdhani-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Rajdhani";
  src: url("./Rajdhani/Rajdhani-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rajdhani";
  src: url("./Rajdhani/Rajdhani-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rajdhani";
  src: url("./Rajdhani/Rajdhani-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Rajdhani";
  src: url("./Rajdhani/Rajdhani-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}