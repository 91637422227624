@import './assets/fonts/typography.css';

.chakra-ui-dark {
  --rdp-bg-color: var(--chakra-colors-gray-800);
  --rdp-text-color: #fff;
  --rdp-bg-hover-color: var(--chakra-colors-gray-600);
  --rdp-border-color: var(--chakra-colors-whiteAlpha-300);
  --rdp-disabled-text-color: var(--chakra-colors-gray-600);
  --rdp-outside-month-text-color: var(--chakra-colors-gray-400);
  --scrollbar-thumb-color: #666666;
  --scrollbar-thumb-hover-color: #999999;
  --scrollbar-track-color: #141414;
}

.chakra-ui-light {
  --rdp-bg-color: var(--chakra-colors-gray-100);
  --rdp-text-color: #000;
  --rdp-bg-hover-color: var(--chakra-colors-gray-300);
  --rdp-border-color: var(--chakra-colors-gray-300);
  --rdp-disabled-text-color: var(--chakra-colors-gray-300);
  --rdp-outside-month-text-color: var(--chakra-colors-gray-500);
  --scrollbar-thumb-color: #898989;
  --scrollbar-thumb-hover-color: #676767;
  --scrollbar-track-color: #dddddd;
}

* {
  scrollbar-width: thin;
  /* thumb-color track-color */
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-color);
}

html,
body,
#root {
  height: 100%;
}
